import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { placa } from 'consts/regexp'
import { createFilters, toRankingOptions, formatFilters } from 'services/utils'
import { Chip, SideBar, Ranking, SearchFilter } from 'components'
import { TrashButton } from 'components/Icons/Trash'

export function IssuedCollectsFilter({ onFiltering, selectedFilters }) {
  const loading = useSelector((state) => state.users.collections.loading)

  const collects = useSelector(
    (state) => state.users.collections.issueds.issueds
  )

  const rankings = useMemo(() => {
    const createRankings = createFilters([
      'dsc_uf',
      'cidade_cliente',
      'cliente_nome',
    ])

    return createRankings(collects)
  }, [collects])

  function handleSelect(filter) {
    const alreadyIncluded = selectedFilters.some((f) => f.id === filter.id)

    if (!alreadyIncluded) {
      onFiltering(selectedFilters.concat(filter))
    }
  }

  function handleUnselect(filter) {
    const updatedFilters = [...selectedFilters].filter(
      (e) => JSON.stringify(e) !== JSON.stringify(filter)
    )

    onFiltering(updatedFilters)
  }

  function handleSubmit(values) {
    onFiltering(formatFilters(values))
  }

  return (
    <SideBar
      loading={loading}
      HeaderComponent={
        <div className="ChipList ChipListSpacing">
          {selectedFilters.map((filter, index) => (
            <Chip
              onDelete={() => handleUnselect(filter)}
              label={filter.value}
              key={index}
            />
          ))}
          <TrashButton onClick={() => onFiltering([])} />
        </div>
      }
      ContentComponent={
        <div className="Ranking-List">
          <Ranking
            filterBy="dsc_uf"
            title="Emitidas por UF"
            rows={toRankingOptions(rankings.dsc_uf)}
            onFiltering={handleSelect}
          />
          <Ranking
            filterBy="cidade_cliente"
            title="Emitidas por Cidade"
            rows={toRankingOptions(rankings.cidade_cliente)}
            onFiltering={handleSelect}
          />
          <Ranking
            filterBy="cliente_nome"
            title="Emitidas por Cliente"
            rows={toRankingOptions(rankings.cliente_nome)}
            onFiltering={handleSelect}
          />
        </div>
      }
      FooterComponent={
        <div style={{ height: '180px' }}>
          <SearchFilter
            onSubmit={handleSubmit}
            title="Pesquisar por"
            buttonLabel="Enviar"
            fields={[
              {
                name: 'id_coleta',
                label: 'Nº Coleta',
                uppercase: true,
              },
              {
                name: 'vpt_placa',
                label: 'Placa',
                textMaskProps: { mask: placa.array },
                uppercase: true,
              },
              { name: 'vpt_motorista', label: 'Motorista' },
            ]}
          />
        </div>
      }
    />
  )
}
