import React, { useRef, useMemo, useState, useEffect } from 'react'

import { Form, FormTextField } from '@/components/CoForm'
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core'

import { cpf, placa } from '@/consts/regexp'
import { toMask } from '@/services/masks'
import { Button } from '@/components'
import { StyledDialogTitle } from '@/components/Dialog/styles'

import { handleValidationError, Yup as y } from '@/services/yup'

import { useDispatch, useSelector } from 'react-redux'
import { updateCollectRequest } from '@/store/modules/users/collections/reducer'
import { Edit } from '@material-ui/icons'

const EditCollectSchema = y.object().shape({
  vpt_placa: y.string().required(),
  vpt_motorista: y
    .string()
    .required()
    .max(30, 'O nome do motorista, deve conter no maxímo 30 carácteres'),
  vpt_cod_motorista: y.string().required(),
})

// -----------------------------------------------------------

export function CollectQuickFormEdit({ initialData }) {
  const [dialogOpen, setDialogOpen] = useState(false)

  const isSubmitting = useSelector((state) => state.users.collections.loading)
  const isSubmitted = useSelector((state) => state.users.collections.submitted)

  const dispatch = useDispatch()

  const formRef = useRef(null)

  const defaultValues = useMemo(
    () => ({
      id_coleta: initialData?.id_coleta || '',
      vpt_placa: initialData?.vpt_placa || '',
      vpt_motorista: initialData?.vpt_motorista || '',
      vpt_cod_motorista: initialData?.vpt_cod_motorista || '',
    }),
    [initialData]
  )

  function handleToogleDialogOpen() {
    setDialogOpen(!dialogOpen)
  }

  function handleSubmitButton() {
    formRef.current.setErrors({})

    const data = formRef.current.getValues()
    handleSubmit(data)
  }

  function handleSubmit(data) {
    const formValidator = handleValidationError(formRef.current)

    EditCollectSchema.validate(data, { abortEarly: false })
      .then((data) => {
        dispatch(
          updateCollectRequest({
            id_coleta: data.id_coleta,
            vpt_placa: data.vpt_placa?.toUpperCase(),
            vpt_motorista: data.vpt_motorista,
            vpt_cod_motorista: data.vpt_cod_motorista,
          })
        )
      })
      .catch((errors) => {
        formValidator(errors)
      })
  }

  useEffect(() => {
    if (isSubmitted) {
      setDialogOpen(false)
    }
  }, [isSubmitted])

  return (
    <>
      <Button
        onClick={handleToogleDialogOpen}
        color="primary"
        variant="outlined"
        size="small"
        startIcon={<Edit />}
      >
        Alterar
      </Button>

      <Form
        formRef={formRef}
        initialData={defaultValues}
        onSubmit={handleSubmit}
        schema={EditCollectSchema}
      >
        <Dialog fullWidth maxWidth="sm" open={dialogOpen}>
          <StyledDialogTitle>Alterar informações da coleta</StyledDialogTitle>

          <DialogContent>
            <Box padding="1rem 0" display="grid" gridGap="1rem">
              <FormTextField
                fullWidth
                size="small"
                name="id_coleta"
                label="Coleta"
                variant="standard"
                disabled
              />

              <FormTextField
                fullWidth
                size="small"
                name="vpt_placa"
                label="Placa"
                textMaskProps={toMask(placa.array)}
                variant="standard"
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />

              <FormTextField
                fullWidth
                size="small"
                label="CPF do Motorista"
                name="vpt_cod_motorista"
                textMaskProps={toMask(cpf.array)}
                variant="standard"
              />

              <FormTextField
                fullWidth
                size="small"
                name="vpt_motorista"
                label="Motorista"
                placeholder="Qual o nome do motorista?"
                variant="standard"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={handleToogleDialogOpen}
            >
              Fechar
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={isSubmitting}
              onClick={handleSubmitButton}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    </>
  )
}
